exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-walk-through-time-becket-index-js": () => import("./../../../src/pages/a-walk-through-time/becket/index.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-becket-index-js" */),
  "component---src-pages-a-walk-through-time-index-js": () => import("./../../../src/pages/a-walk-through-time/index.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-index-js" */),
  "component---src-pages-a-walk-through-time-lanfranc-index-js": () => import("./../../../src/pages/a-walk-through-time/lanfranc/index.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-lanfranc-index-js" */),
  "component---src-pages-a-walk-through-time-reformation-index-js": () => import("./../../../src/pages/a-walk-through-time/reformation/index.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-reformation-index-js" */),
  "component---src-pages-a-walk-through-time-st-augustine-index-js": () => import("./../../../src/pages/a-walk-through-time/st-augustine/index.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-st-augustine-index-js" */),
  "component---src-pages-a-walk-through-time-timeline-js": () => import("./../../../src/pages/a-walk-through-time/timeline.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-timeline-js" */),
  "component---src-pages-a-walk-through-time-vikings-index-js": () => import("./../../../src/pages/a-walk-through-time/vikings/index.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-vikings-index-js" */),
  "component---src-pages-a-walk-through-time-ww-2-index-js": () => import("./../../../src/pages/a-walk-through-time/ww2/index.js" /* webpackChunkName: "component---src-pages-a-walk-through-time-ww-2-index-js" */),
  "component---src-pages-how-did-they-build-that-building-the-cathedral-index-js": () => import("./../../../src/pages/how-did-they-build-that/building-the-cathedral/index.js" /* webpackChunkName: "component---src-pages-how-did-they-build-that-building-the-cathedral-index-js" */),
  "component---src-pages-how-did-they-build-that-glass-index-js": () => import("./../../../src/pages/how-did-they-build-that/glass/index.js" /* webpackChunkName: "component---src-pages-how-did-they-build-that-glass-index-js" */),
  "component---src-pages-how-did-they-build-that-index-js": () => import("./../../../src/pages/how-did-they-build-that/index.js" /* webpackChunkName: "component---src-pages-how-did-they-build-that-index-js" */),
  "component---src-pages-how-did-they-build-that-programmes-of-study-index-js": () => import("./../../../src/pages/how-did-they-build-that/programmes-of-study/index.js" /* webpackChunkName: "component---src-pages-how-did-they-build-that-programmes-of-study-index-js" */),
  "component---src-pages-how-did-they-build-that-stained-glass-index-js": () => import("./../../../src/pages/how-did-they-build-that/stained-glass/index.js" /* webpackChunkName: "component---src-pages-how-did-they-build-that-stained-glass-index-js" */),
  "component---src-pages-how-did-they-build-that-stone-index-js": () => import("./../../../src/pages/how-did-they-build-that/stone/index.js" /* webpackChunkName: "component---src-pages-how-did-they-build-that-stone-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-header-js": () => import("./../../../src/pages/learn-header.js" /* webpackChunkName: "component---src-pages-learn-header-js" */),
  "component---src-pages-pilgrimage-chaucer-index-js": () => import("./../../../src/pages/pilgrimage/chaucer/index.js" /* webpackChunkName: "component---src-pages-pilgrimage-chaucer-index-js" */),
  "component---src-pages-pilgrimage-index-js": () => import("./../../../src/pages/pilgrimage/index.js" /* webpackChunkName: "component---src-pages-pilgrimage-index-js" */),
  "component---src-pages-pilgrimage-medieval-pilgrimage-index-js": () => import("./../../../src/pages/pilgrimage/medieval-pilgrimage/index.js" /* webpackChunkName: "component---src-pages-pilgrimage-medieval-pilgrimage-index-js" */),
  "component---src-pages-pilgrimage-pilgrimage-today-index-js": () => import("./../../../src/pages/pilgrimage/pilgrimage-today/index.js" /* webpackChunkName: "component---src-pages-pilgrimage-pilgrimage-today-index-js" */),
  "component---src-pages-pilgrimage-what-is-pilgrimage-index-js": () => import("./../../../src/pages/pilgrimage/what-is-pilgrimage/index.js" /* webpackChunkName: "component---src-pages-pilgrimage-what-is-pilgrimage-index-js" */),
  "component---src-pages-the-cathedral-index-js": () => import("./../../../src/pages/the-cathedral/index.js" /* webpackChunkName: "component---src-pages-the-cathedral-index-js" */)
}

